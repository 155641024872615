import React from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { IMainState } from '../../../interfaces';
import {
  DID_CLICK_COPY_NUDGE,
  DID_CLOSE_NUDGE_PREVIEW
} from '../../../utils/constants';
import { PageView } from '../../../utils/pageView';
import { shouldShowPriceOptions } from '../../utils/hubEntitlements';
import { getThemeColor } from '../BrandStyle';
import Alert from '../Common/Alert';
import { HubContext } from '../HubContext';
import { hubNavTo } from './../../utils/hubPagesRoute';
import NudgeMicroSite from './NudgeMicroSite';
import NudgePriceOptions from './NudgePriceOptions';
import NudgeTemplateActions from './NudgeTemplateActionsButton';
import NudgeSendButton from './NudgeSendButton';

export enum TemplatePreviewAction {
  VIEW = 'VIEW',
  SAVE = 'SAVE',
  EDIT = 'EDIT',
  USE = 'USE'
}

const NudgePreview = ({
  onBack,
  templateAction
}: {
  onBack: () => void;
  templateAction?: TemplatePreviewAction;
}) => {
  const { brandId, storeId } = React.useContext(HubContext);
  const color = getThemeColor(brandId);
  const hubState = useSelector((state: IMainState) => state.clientState.hub);
  const nudgeState = hubState.nudge || {};
  const isNew = !nudgeState?.status;

  const nudgeActionsButton = isNew ? (
    <NudgeSendButton onBack={onBack} />
  ) : (
    <div className="btn-group control-fix">
      <button
        className="btn btn-secondary"
        type="button"
        onClick={() => {
          logEvent(DID_CLOSE_NUDGE_PREVIEW, DID_CLOSE_NUDGE_PREVIEW, {
            nudgeId: nudgeState.id
          });
          hubNavTo(PageView.HUB_NUDGES);
        }}
      >
        CLOSE
      </button>

      <button
        className="btn btn-accent"
        onClick={() => {
          logEvent(DID_CLICK_COPY_NUDGE, DID_CLICK_COPY_NUDGE, {
            nudgeId: nudgeState.id
          });
          hubNavTo(PageView.HUB_NUDGES_COPY);
        }}
      >
        COPY AS NEW NUDGE
      </button>
    </div>
  );

  return (
    <div className="NudgePreview">
      <NudgeMicroSite brandId={brandId} nudge={nudgeState} />
      {shouldShowPriceOptions(storeId) && (
        <NudgePriceOptions disabled={!isNew} />
      )}

      {templateAction ? (
        <NudgeTemplateActions onBack={onBack} actionType={templateAction} />
      ) : (
        nudgeActionsButton
      )}
      <Alert />
      <style jsx>{`
        .NudgePreview {
          background: ${color.dark};
        }
        .NudgePreview :global(.btn-accent) {
          background: ${color.btnPrimary};
          color: ${color.btnPrimaryText};
        }
        .NudgePreview :global(.control-fix) {
          bottom: 0;
          left: 0;
          right: 0;
          position: fixed;
          z-index: 10;
        }
        @media (max-width: 768px) {
          .NudgePreview :global(button) {
            width: auto;
            border-radius: 0;
          }
        }
        @media (min-width: 768px) {
          .NudgePreview :global(.control-fix) {
            bottom: 20px;
            position: fixed;
            left: 50%;
            width: 400px;
            transform: translateX(-50%);
          }
        }
      `}</style>
    </div>
  );
};

export default NudgePreview;
