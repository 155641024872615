import React from 'react';
import { PageView } from '../../../utils/pageView';
import { NudgeCreationState } from '../Nudge/NudgeCurator';
import { logEvent } from './../../../analytics/index';
import {
  DID_CLICK_BACK_BUTTON,
  DID_CLICK_NEXT_BUTTON
} from './../../../utils/constants';

export const WizardButton = ({
  onNext,
  onPrev,
  currentPage,
  nextPage,
  prevPage,
  isHideBackButton,
  isHideNextButton,
  isDisabledNextButton
}: {
  onNext: () => void;
  onPrev: () => void;
  currentPage: string | NudgeCreationState | PageView;
  nextPage?: string | NudgeCreationState | PageView | undefined;
  prevPage?: string | NudgeCreationState | PageView | undefined;
  isHideBackButton?: boolean;
  isHideNextButton?: boolean;
  isDisabledNextButton?: boolean;
}) => (
  <div className="WizardButton">
    <div className="text-center btn-group">
      {!isHideBackButton && (
        <button
          className="btn btn-secondary"
          onClick={() => {
            onPrev();
            logEvent(DID_CLICK_BACK_BUTTON, DID_CLICK_BACK_BUTTON, {
              currentPage,
              targetPage: prevPage
            });
          }}
        >
          BACK
        </button>
      )}
      {!isHideNextButton && (
        <button
          className="btn btn-accent"
          onClick={() => {
            onNext();
            logEvent(DID_CLICK_NEXT_BUTTON, DID_CLICK_NEXT_BUTTON, {
              currentPage,
              targetPage: nextPage
            });
          }}
          disabled={isDisabledNextButton}
        >
          NEXT
        </button>
      )}
    </div>
    <style jsx>{`
      .WizardButton {
        margin-top: 20px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
      }
      .WizardButton .btn-group {
        width: 100%;
      }

      @media (max-width: 768px) {
        .WizardButton .btn {
          border-radius: 0;
        }
      }
      @media (min-width: 768px) {
        .WizardButton {
          width: 400px;
          position: relative;
          left: auto;
          right: auto;
          bottom: auto;
          margin: 30px auto;
        }
        :global(.ssp) .WizardButton {
          width: 800px;
        }
        :global(.ssp) .WizardButton .btn {
          font-size: 1.4em !important;
        }
      }
    `}</style>
  </div>
);
