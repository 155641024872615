import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { IMainState, ProgressStatus } from '../../../interfaces';
import { DID_SHARE_NUDGE } from '../../../utils/constants';
import { PageView } from '../../../utils/pageView';
import { getCurrentHost, performNativeShare } from '../../../utils/window';
import { getThemeColor } from '../BrandStyle';
import {
  actionHubClearNudge,
  actionHubUpdateProgressStatus
} from './../../../redux/actions';
import { actionSaveNudgeAsync } from './../../../redux/advisorHubAsyncActions';
import { getSimpleShortenedUrl } from './../../../utils/shareUrl';
import { hubNavTo } from './../../utils/hubPagesRoute';
import { HubContext } from './../HubContext';
import SimpleShareModal from './../../../components/Sharing/SimpleShareModal';
import { QRCode } from '../../../components/QRCode';

const NudgeSendButton = ({ onBack }: { onBack: () => void }) => {
  const dispatch = useDispatch();
  const { brandId, isEmbeddedInSsp } = useContext(HubContext);
  const color = getThemeColor(brandId);

  const [localState, setLocalState] = React.useState({
    shareUrl: null,
    showShareList: false
  });

  const { shareUrl, showShareList } = localState;

  const hubState = useSelector((state: IMainState) => state.clientState.hub);
  const nudgeState = hubState.nudge;

  const progressStatus = hubState?.progressStatus || ProgressStatus.INITIAL;
  const title = nudgeState?.title || '';

  const hasBeenSaved = nudgeState?.createdBy;

  const getLabel = () => {
    switch (progressStatus) {
      case ProgressStatus.LOADING:
        return <Spinner size="sm" animation="border" />;
      case ProgressStatus.FAILED:
        return 'TRY AGAIN';
      default:
        return 'SEND';
    }
  };
  const handleClose = () => {
    dispatch(actionHubClearNudge());
    hubNavTo(PageView.HUB_NUDGES);
  };

  const onShare: any = (channel = '') => {
    logEvent(DID_SHARE_NUDGE, channel, {
      nudgeId: nudgeState?.id,
      shareUrl: localState.shareUrl,
      message: title
    });
  };
  React.useEffect(() => {
    const query = isEmbeddedInSsp ? '?sharable=true' : '';
    dispatch(actionHubUpdateProgressStatus(ProgressStatus.INITIAL));
    if (nudgeState?.id) {
      const originalUrl = `${getCurrentHost()}/nudge/${nudgeState.id}${query}`;
      if (typeof getSimpleShortenedUrl === 'function') {
        getSimpleShortenedUrl(originalUrl)
          .then((url) => setLocalState({ ...localState, shareUrl: url }))
          .catch(() => setLocalState({ ...localState, shareUrl: originalUrl }));
      } else {
        setLocalState({ ...localState, shareUrl: originalUrl });
      }
    }
  }, [isEmbeddedInSsp]);

  const shareInSsp = () => {
    setLocalState({ ...localState, showShareList: !showShareList });
    if (!hasBeenSaved) {
      dispatch(actionSaveNudgeAsync(nudgeState));
    }
  };

  const buttonSend = isEmbeddedInSsp ? (
    <button className="btn btn-accent" onClick={shareInSsp}>
      {getLabel()}
    </button>
  ) : (
    <button
      className="btn btn-accent"
      onClick={() => {
        if (shareUrl) {
          const onNativeShare = () => {
            logEvent(DID_SHARE_NUDGE, 'native', {
              nudgeId: nudgeState?.id,
              shareUrl,
              message: title
            });
          };
          const onFailedNativeShare = () => {
            setLocalState({
              ...localState,
              showShareList: !showShareList
            });
          };
          performNativeShare(
            {
              title: title,
              text: title,
              url: shareUrl
            },
            onNativeShare,
            onFailedNativeShare
          );
        }

        if (!hasBeenSaved) {
          dispatch(actionSaveNudgeAsync(nudgeState));
        }
      }}
    >
      {getLabel()}
    </button>
  );
  return (
    <>
      <div className="btn-group control-fix">
        <button
          className={`btn btn-secondary ${
            !hasBeenSaved ? 'btn-back-nudge' : 'btn-close-nudge'
          }`}
          type="button"
          onClick={!hasBeenSaved ? onBack : handleClose}
        >
          {!hasBeenSaved ? 'BACK' : 'CLOSE'}
        </button>
        {buttonSend}
      </div>

      {!isEmbeddedInSsp ? (
        <SimpleShareModal
          show={showShareList}
          url={shareUrl}
          title={title}
          onShare={onShare}
        />
      ) : (
        showShareList &&
        shareUrl && (
          <div className="qr-share">
            <QRCode title={title} value={shareUrl || ''} size={300}></QRCode>
          </div>
        )
      )}
      <style jsx>{`
        .btn-accent {
          background: ${color.btnPrimary};
          color: ${color.btnPrimaryText};
        }
        .qr-share {
          bottom: 70px;
          top: auto;
          position: fixed;
          left: calc(50% - 200px);
          width: 400px;
        }
        .control-fix {
          bottom: 0;
          left: 0;
          right: 0;
          position: fixed;
          z-index: 10;
        }

        @media (max-width: 768px) {
          button {
            width: auto;
            border-radius: 0;
          }
        }
        @media (min-width: 768px) {
          .control-fix {
            transform: translateX(-50%);
            bottom: 20px;
            position: fixed;
            left: 50%;
            width: 400px;
          }
        }
      `}</style>
    </>
  );
};

export default NudgeSendButton;
