import React from 'react';

interface Props {
  onSelectContext: (str: string) => void;
  onSelectLanguage: (str: string) => void;
  contextFilters: { value: string; label: string }[];
  languageFilters: { value: string; label: string }[];
  selectedLanguage: string;
  selectedContext: string[];
  onClose: () => void;
  onReset: () => void;
  darkMode?: boolean;
}

const StoryFilters = ({
  selectedContext,
  onSelectContext,
  selectedLanguage,
  onSelectLanguage,
  languageFilters,
  contextFilters,
  onClose,
  onReset,
  darkMode = false
}: Props) => {
  return (
    <div className="StoryFilters">
      <div className="filter-items text-left">
        <div className="filter-group">
          <h4>LANGUAGES:</h4>
          <button
            className={`btn btn-sm btn-filter btn-outline-secondary ${
              selectedLanguage === '' ? 'active' : ''
            }`}
            onClick={() => onSelectLanguage('')}
          >
            All
          </button>
          {languageFilters.map((language, i) => (
            <button
              key={i + language.value}
              className={`btn btn-sm btn-filter btn-outline-secondary ${
                selectedLanguage === language.value ? 'active' : ''
              }`}
              onClick={() => onSelectLanguage(language.value)}
            >
              {language.label}
            </button>
          ))}
        </div>
        <div className="filter-group">
          <h4>CONTEXT:</h4>
          {contextFilters.map((f, i) => (
            <button
              key={i + f.value}
              className={`btn btn-sm btn-filter btn-outline-secondary ${
                selectedContext.indexOf(f.value) > -1 ? 'active' : ''
              }`}
              onClick={() => onSelectContext(f.value)}
            >
              {f.label}
            </button>
          ))}
        </div>
      </div>
      <div className="control">
        <button className="btn btn-sm" onClick={onReset}>
          RESET FILTER
        </button>
        <button className="btn btn-sm" onClick={onClose}>
          CLOSE
        </button>
      </div>
      <style jsx>{`
        .StoryFilters{
          background: ${darkMode ? '#000' : '#fff'};
          border: ${darkMode ? '1px solid #444' : 'none'};
        }
        h4 {
          font-size: 0.8em;
          letter-spacing: 1px;
          text-transform: uppercase;
          opacity: 0.5;
        }
        :global(.ssp) h4 {
          font-size: 1em;
        }
        .filter-items {
          padding: 20px;
          max-height: 280px;
          overflow: auto;
        }
        .filter-items button {
          margin: 0 5px 5px 0;
        }

        .control {
          border-top: 1px solid ${darkMode ? '#444' : '#efefef'};
          text-align: right;
          padding: 5px;
        }
        .control button {
          color: ${darkMode ? '#fff' : '#000'};
          margin-left: 5px;
        }
        :global(.ssp) .filter-items button,
        :global(.ssp) .control button {
          font-size: 1.3em;
          padding:10px;
        }
        .btn-filter{
          color:${darkMode ? '#ccc' : '#6c757d'}
        }
        .btn-filter:hover { 
          color: ${darkMode ? '#000' : '#fff'};
        }
        .filter-group {
          margin-bottom: 10px;
        }
        .filter-group:last-child {
          margin-bottom: 0;
        }
        @media (min-width: 768px) {
          .filter-items {
            max-height: none;
          }
        }
      `}</style>
    </div>
  );
};

export default StoryFilters;
