import React from 'react';
import { changeContentOrder, isInspiration } from '../../utils/nudge';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { truncate } from 'lodash';
import {
  IProduct,
  INudgeItem,
  IInspiration,
  IMainState
} from '../../../interfaces';
import { isMobileViewport } from '../../../utils/deviceDetector';
import { getProductDisplayName } from '../../../utils/product';
import { useSelector } from 'react-redux';

interface Props {
  items: INudgeItem[];
  onUpdate: (items: INudgeItem[]) => void;
  darkMode?: boolean;
}

const sortContent = (
  index: string,
  items: INudgeItem[],
  language: string,
  isMobile: boolean,
  handleDelete: (index: number) => void
) => {
  const sortItem = items[Number(index)] as IProduct;
  let item = {
    image: sortItem.previewImageUrl,
    title: getProductDisplayName(sortItem, language)
  };
  if (isInspiration(sortItem)) {
    const sortItemInspiration = sortItem as unknown as IInspiration;
    item = {
      image: sortItemInspiration.flipImageUrl,
      title: sortItemInspiration.title
    };
  }
  return (
    <li className="sortItem">
      <div
        className="img-container"
        style={{ backgroundImage: `url(${item.image})` }}
      ></div>
      <div className="title-container">
        <span> {truncate(item.title, { length: isMobile ? 23 : 50 })}</span>
      </div>
      {items.length > 1 && (
        <div className="action-container">
          <button className="close" onClick={() => handleDelete(Number(index))}>
            &times;
          </button>
        </div>
      )}
    </li>
  );
};

const SortableItem = SortableElement(
  ({ value, items, language, isMobile, handleDelete }) =>
    sortContent(value, items, language, isMobile, handleDelete)
);

const SortableList = SortableContainer(
  ({ items, language, isMobile, handleDelete }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            {...({
              value: index,
              items,
              language,
              isMobile,
              handleDelete
            } as any)}
          />
        ))}
      </ul>
    );
  }
);

export const ContentSortable = ({
  items,
  onUpdate,
  darkMode = true
}: Props) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    if (isMobileViewport()) setIsMobile(true);
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onUpdate(changeContentOrder(items, oldIndex, newIndex));
  };

  const handleDelete = (index: number) => {
    const newItems = [...items];
    if (items.length > 1) newItems.splice(index, 1);
    onUpdate(newItems);
  };

  const language = useSelector(
    (state: IMainState) =>
      state.clientState.hub?.nudge?.languageCode?.toLowerCase() || 'en'
  );

  return (
    <>
      <div className="ContentSortable">
        <SortableList
          helperClass="sortItem sortActive"
          onSortEnd={onSortEnd}
          lockAxis="y"
          {...({ items: items, language, isMobile, handleDelete } as any)}
        />
      </div>
      <style jsx global>{`
        .ContentSortable ul,
        .sortItem {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        .sortItem {
          border: 1px solid ${darkMode ? '#545454' : '#ddd'};
          margin: 5px 0;
          color: ${darkMode ? '#fff' : '#000'};
          padding: 0;
          background: ${darkMode ? '#333' : '#efefef'};
          overflow: hidden;
          font-size: 0.7em;
          cursor: move;
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;
          border-radius: 3px;
          text-align: left;
        }
        :global(.ssp) .sortItem {
          font-size: 1.2em;
        }
        .sortItem .close {
          float: right;
          margin-top: 23px;
          margin-right: 10px;
          text-shadow: none;
          color: ${darkMode ? '#fff' : '#000'};
          font-weight: normal;
        }
        :global(.ssp) .sortItem .close {
          font-size: 2em;
        }

        .sortItem .img-container {
          height: 70px;
          width: 70px;
          background-size: cover;
          background-position: center center;
          float: left;
          margin-right: 10px;
          background-color: #fff;
        }
        :global(.ssp) .sortItem .img-container {
          height: 100px;
          width: 100px;
        }
        .sortItem .title-container {
          float: left;
          width: calc(100% - 120px);
          display: flex;
          align-items: center;
          height: 70px;
        }
        :global(.ssp) .sortItem .title-container {
          width: calc(100% - 150px);
          height: 100px;
        }
        .sortActive {
          z-index: 2000;
        }
        .sortActive .close {
          display: none;
        }
        @media (min-width: 768px) {
          .sortItem {
            font-size: 0.9em;
          }
        }
      `}</style>
    </>
  );
};
