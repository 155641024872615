import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { IMainState } from '../../../interfaces';
import { actionHubUpdateNudgeCurrency } from '../../../redux/actions';
import { DID_SELECT_NUDGE_CURRENCY } from './../../../utils/constants';

const NudgePriceOptions = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useDispatch();

  const nudgeState = useSelector(
    (state: IMainState) => state.clientState.hub?.nudge
  );

  const currencies = {
    EU: 'EUR',
    CH: 'CHF',
    US: 'USD'
  };

  const handeSelectCurrency = (event: React.ChangeEvent<HTMLSelectElement>) => {
    logEvent(DID_SELECT_NUDGE_CURRENCY, DID_SELECT_NUDGE_CURRENCY, {
      countryCode: event.target.value || '',
      currency: event.target.value ? currencies[event.target.value] : ''
    });
    dispatch(
      actionHubUpdateNudgeCurrency(
        event.target.value
          ? {
              countryCode: event.target.value,
              currency: currencies[event.target.value]
            }
          : undefined
      )
    );
  };

  React.useEffect(() => {
    if (!nudgeState.currency) {
      dispatch(actionHubUpdateNudgeCurrency(undefined));
    }
  }, []);
  return (
    <div className="currencies">
      <div className="form-group row">
        <label htmlFor="currencyOptions" className="col-2 col-form-label">
          Price:
        </label>
        <select
          className="form-control col-10"
          id="currencyOptions"
          onChange={handeSelectCurrency}
          value={nudgeState.currency || ''}
          disabled={disabled}
        >
          <option value="">Hide Price</option>
          {Object.keys(currencies).map((countryCode) => (
            <option key={countryCode} value={countryCode}>
              {currencies[countryCode]}
            </option>
          ))}
        </select>
      </div>

      <style jsx>{`
        .currencies {
          bottom: 38px;
          left: 0;
          right: 0;
          position: fixed;
          z-index: 10;
          border-radius: 4px;
          padding: 10px;
          background: #efefef;
        }
        .form-group {
          margin: 0;
        }
        @media (min-width: 768px) {
          .currencies {
            bottom: 68px;
            position: fixed;
            left: 50%;
            width: 400px;
            transform: translateX(-50%);
          }
        }
      `}</style>
    </div>
  );
};

export default NudgePriceOptions;
