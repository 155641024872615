import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { IMainState, ProgressStatus } from '../../../interfaces';
import { PageView } from '../../../utils/pageView';
import { getThemeColor } from '../BrandStyle';
import { actionHubClearNudge, actionHubUpdateProgressStatus } from '../../../redux/actions';
import { actionSaveNudgeTemplateAsync } from '../../../redux/advisorHubAsyncActions';
import { hubNavTo } from '../../utils/hubPagesRoute';
import { HubContext } from '../HubContext';
import {
  DID_CLICK_BUTTON,
  DID_CLICK_USE_NUDGE_TEMPLATE,
  DID_CLOSE_NUDGE_TEMPLATE_PREVIEW
} from '../../../utils/constants';
import { TemplatePreviewAction } from './NudgePreview';

const NudgeTemplateActionsButton = ({
  onBack,
  actionType
}: {
  onBack: () => void;
  actionType?: TemplatePreviewAction;
}) => {
  const dispatch = useDispatch();
  const { brandId } = React.useContext(HubContext);
  const color = getThemeColor(brandId);

  const hubState = useSelector((state: IMainState) => state.clientState.hub);
  const nudgeState = hubState.nudge;
  const templateId = nudgeState?.id;
  const progressStatus = hubState?.progressStatus || ProgressStatus.INITIAL;

  const isSuccess = progressStatus === ProgressStatus.SUCCESSFUL;

  const getSaveLabel = () => {
    switch (progressStatus) {
      case ProgressStatus.LOADING:
        return <Spinner size="sm" animation="border" />;
      case ProgressStatus.FAILED:
        return 'TRY AGAIN';
      default:
        return 'SAVE TEMPLATE';
    }
  };

  const useButton = (
    <button
      className="btn btn-accent template-use"
      onClick={() => {
        logEvent(DID_CLICK_USE_NUDGE_TEMPLATE, DID_CLICK_USE_NUDGE_TEMPLATE, {
          templateId
        });
        hubNavTo(PageView.HUB_NUDGES_COPY);
      }}
    >
      USE THIS TEMPLATE
    </button>
  );

  const saveButton = (
    <button
      className="btn btn-accent template-save"
      onClick={() => {
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
          button: 'SAVE NUDGE TEMPLATE'
        });
        dispatch(actionSaveNudgeTemplateAsync(nudgeState));
      }}
    >
      {getSaveLabel()}
    </button>
  );

  const editButton = (
    <button
      className="btn btn-accent template-edit"
      onClick={() => {
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
          button: 'EDIT NUDGE TEMPLATE',
          templateId
        });
        dispatch(actionHubUpdateProgressStatus(ProgressStatus.INITIAL))
        onBack();
      }}
    >
      EDIT
    </button>
  );

  const editButton2 = (
    <button
      className="btn btn-dark template-edit"
      onClick={() => {
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
          button: 'EDIT NUDGE TEMPLATE',
          templateId
        });
      
        hubNavTo(PageView.HUB_NUDGE_TEMPLATE_EDIT);
      }}
    >
      EDIT
    </button>
  );

  const copyButton = (
    <button
      className="btn btn-accent template-copy"
      onClick={() => {
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
          button: 'COPY NUDGE TEMPLATE',
          templateId
        });
        hubNavTo(PageView.HUB_NUDGE_TEMPLATE_COPY);
      }}
    >
      COPY
    </button>
  );

  const closeButton = (navTo: PageView) => (
    <button
      className="btn btn-secondary template-close"
      type="button"
      onClick={() => {
        logEvent(
          DID_CLOSE_NUDGE_TEMPLATE_PREVIEW,
          DID_CLOSE_NUDGE_TEMPLATE_PREVIEW,
          {
            templateId
          }
        );
        hubNavTo(navTo);
        dispatch(actionHubClearNudge());
      }}
    >
      CLOSE
    </button>
  );

  const backButton = (
    <button
      className="btn btn-secondary template-back"
      type="button"
      onClick={onBack}
    >
      BACK
    </button>
  );

  return (
    <>
      <div className="btn-group control-fix">
        {actionType === TemplatePreviewAction.SAVE &&
          (!isSuccess ? (
            <>
              {backButton}
              {saveButton}
            </>
          ) : (
            <>
              {closeButton(PageView.HUB_NUDGE_TEMPLATES)}
              {editButton}
            </>
          ))}

        {actionType === TemplatePreviewAction.EDIT && (
          <>
            {closeButton(PageView.HUB_NUDGE_TEMPLATES)}
            {editButton2}
            {copyButton}
          </>
        )}

        {actionType === TemplatePreviewAction.VIEW && (
          <>
            {closeButton(PageView.HUB_NUDGE_TEMPLATES_SELECT)}
            {useButton}
          </>
        )}
      </div>

      <style jsx>{`
        .btn-accent {
          background: ${color.btnPrimary};
          color: ${color.btnPrimaryText};
        }

        .control-fix {
          bottom: 0;
          left: 0;
          right: 0;
          position: fixed;
          z-index: 10;
        }

        @media (max-width: 768px) {
          button {
            width: auto;
            border-radius: 0;
          }
        }
        @media (min-width: 768px) {
          .control-fix {
            transform: translateX(-50%);
            bottom: 20px;
            position: fixed;
            left: 50%;
            width: 400px;
          }
        }
      `}</style>
    </>
  );
};

export default NudgeTemplateActionsButton;
