import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { actionHubUpdateNudgeLanguage } from '../../../redux/actions';
import { DID_SELECT_NUDGE_LANGUAGE } from '../../../utils/constants';
import { knownLanguages } from '../../config';
import { getThemeColor } from '../BrandStyle';
import { RiArrowDownSLine } from 'react-icons/ri';
import { IMainState } from '../../../interfaces';

const NudgeLanguageSelect = ({ brandId }: { brandId: string }) => {
  const dispatch = useDispatch();
  const color = getThemeColor(brandId);
  const language = useSelector(
    (state: IMainState) => state.clientState.hub?.nudge?.languageCode || 'EN'
  );
  return (
    <>
      <div className="input-group language-select">
        <select
          className="form-control"
          id="language-options"
          value={language}
          onChange={(e) => {
            logEvent(DID_SELECT_NUDGE_LANGUAGE, DID_SELECT_NUDGE_LANGUAGE, {
              language: e.target.value
            });
            dispatch(actionHubUpdateNudgeLanguage(e.target.value));
          }}
        >
          {knownLanguages.map((lang) => (
            <option key={lang.label} value={lang.value}>
              {lang.label.toUpperCase()}
            </option>
          ))}
        </select>
        <RiArrowDownSLine />
      </div>
      <style jsx>{`
        .input-group {
          position: relative;
        }
        .input-group :global(svg) {
          position: absolute;
          right: 2px;
          top: 11px;
          z-index: 20;
        }
        select,
        select:active,
        select:hover,
        select:visited,
        select:focus {
          border-radius: 0;
          background: ${color.nudgeLightMode ? color.light : color.dark};
          color: ${color.nudgeLightMode ? color.dark : color.light};
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          border: 1px solid ${color.accent};
        }
        select:focus option {
          background: ${color.nudgeLightMode ? color.light : color.dark};
          color: ${color.nudgeLightMode ? color.dark : color.light};
          padding: 10px;
        }
      `}</style>
    </>
  );
};

export default NudgeLanguageSelect;
